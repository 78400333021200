import React from "react"
import { graphql } from "gatsby"
import Layout from "wildComponents/Layout"
import { Seo } from "wildComponents/seo/components"
import LPHero from "../sections/shared/LPHero"
import LPHowMuch from "../sections/shared/LPHowMuch"
import DarkGroup from "../components/common/DarkGroup"
import LPContact from "../sections/shared/LPContact"
import Perks from "../sections/page-specific/home/Perks"
import PerksText from "../sections/page-specific/home/PerksText"
import { useTheme } from "@chakra-ui/react"

export const Head = ({ location, data, pageContext }) => (
  <Seo ctx={pageContext} data={data} location={location} />
)

function LandingPage4(props) {
  const { variables } = useTheme()
  const { containerPaddingY, componentSpacing } = variables
  return (
    <Layout>
      <LPHero data={props.data.wpPage.acf_hero} smallHeading />
      <DarkGroup>
        <LPHowMuch data={props.data.wpPage.acf_cost_calculator} />
        <PerksText />
        <Perks
          link="/cost-calculator"
          linkText="Calculate Cost"
          containerStyles={{
            py: 0,
            pb: containerPaddingY,
            pt: componentSpacing,
          }}
        />
      </DarkGroup>
      <LPContact text="GOT QUESTIONS? NEED MORE INFO? We’re here to listen to what your business needs are and to give you the best advice on how to get the wheels in motion." />
    </Layout>
  )
}

export default LandingPage4

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      ...HERO_DATA
      ...LP_FORM_DATA
      ...CC_DATA
      ...TWO_COL_DATA
      ...HEADING_LEFT_DATA
    }
  }
`
